import React from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";

const PrivateRoute = ({
  component: Component,
  authenticationComponent: AuthenticationComponent,
  ...rest
}) => (
  <Route
    render={props =>
      localStorage.getItem("Authorize") ? (
        <Component isAuthorize={true} {...props} />
      ) : (
        <AuthenticationComponent isAuthorize={false} {...props} />
      )
    }
    {...rest}
  />
);

PrivateRoute.propTypes = {
  rest: PropTypes.any,
  component: PropTypes.any,
  authenticationComponent: PropTypes.any
};

export default PrivateRoute;
